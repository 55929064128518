import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Address, defaultAddress, LGAStyle, MapStyle, OverlayStyle } from './MapInterface';
import Search from './Search';
import { mapOptions, stateCoordinates } from './MapConstants';
import {
    CoodToGoogleLatLng,
    getFeatureFromGeojson,
    incompleteStates,
    isInsidePolygon,
} from './helper';
import {
    getCouncilRulesForOneMlb,
    getAllAdvertisement,
    getAllCouncilLinks,
    getImageUrlFromS3,
    getFileFromS3,
} from '../../dbquery/dbquery';
import { openInNewTab } from '../../helper';
import {
    Button,
    ButtonGroup,
    FormControl,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import CouncilRules from '../featuresTool/CouncilRules';
import councilImage from '../../asset/svg/government-5.svg';
import InfoLayout from './InfoLayout';
import { useDispatch, useSelector } from 'react-redux';
import { addressAndMapActions } from '../../redux/addressAndMapSlice';
import LoadingSpinner from '../Widgets/LoadingSpinner';

function Map() {
    const mapRef = useRef<any>(null);
    const dispatch = useDispatch();
    const [lastClickAddress, setlastClickAddress] = useState<Address>();
    const [state, setState] = useState('');
    const [map, setMap] = React.useState<any>();
    const [zoomLevel, setZoomLevel] = useState<number>(4);
    const [showCouncilIcons, setShowCouncilIcons] = useState(false);
    const [showManufacturerAds, setShowManufacturerAds] = useState(true);
    const [openCouncilRulePage, setOpenCouncilRulePage] = useState(false);
    const [councilRules, setCouncilRules] = useState<any>([]);
    const [showCouncilRulesNotification, setShowCouncilRulesNotification] =
        useState<boolean>(false);
    const address = useSelector((state: any) => state.addressAndMap.address);
    const [serviceGEOJSON, setServiceGEOJSON] = useState('J');
    const accessTokenJWT = useSelector((state: any) => state.accountToken.accessTokenJWT);

    const [currentMapStyle, setCurrentMapStyle] = useState<MapStyle>();

    const handleRadioChange = async (value: string) => {
        setMapStyle(
            { selectedService: value, selectedState: state, loadedMLBFeatures },
            showCouncilIcons,
        );
        setServiceGEOJSON(value);
    };

    const [loading, setLoading] = useState(true);
    const [loadedMLBFeatures, setLoadedMLBFeatures] = useState(['WA', 'NT', 'NZ']);

    useEffect(() => {
        const mapData = mapRef.current?.data;
        if (!mapData) return;
        const dataListener = mapRef.current.data.addListener('click', onMapClick);

        // Cleanup function to remove the listener when the component unmounts
        return () => {
            google.maps.event.removeListener(dataListener);
        };
    }, [state, serviceGEOJSON, mapRef.current?.data, loadedMLBFeatures]);

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            location: CoodToGoogleLatLng({ lat: -37.99, lng: 145.3832 }),
            radius: 200 * 1000,
        },
    });

    const onLoad = useCallback(
        (domObject: any, ref: React.MutableRefObject<any>, func?: () => void) => {
            if (ref.current) return;
            ref.current = domObject;
            if (func) func();
        },
        [],
    );

    const loadGeoJsonForState = async (folder: string, state: string) => {
        if (!state) return { type: 'FeatureCollection', features: [] };
        try {
            const urlResponse = await getFileFromS3('suwares', `${folder}/Ovrlay_${state}.json`);
            const url = urlResponse.results;
            const response = await fetch(url);
            const geoJsonData = await response.json();
            geoJsonData.features.forEach((feature: any) => {
                feature.properties.state = state;
            });
            return geoJsonData;
        } catch (error) {
            console.error(`Error loading GeoJSON for state ${state}:`, error);
            return { type: 'FeatureCollection', features: [] };
        }
    };

    // const images = importAll(require.context('../assets/svg', false, /\.(png|jpe?g|svg)$/));
    const [allAdvertisementInfo, setAllAdvertisementInfo] = useState<any>([]);
    const [allCouncilLinks, setAllCouncilLinks] = useState<any>([]);
    useEffect(() => {
        (async () => {
            const allAdvertisementInfo = await getAllAdvertisement();
            const councilLinks = await getAllCouncilLinks().then((res) => {
                const newtemp = res.map((council: any) => {
                    return {
                        lat: council.lat,
                        lng: council.long,
                        link: council.link,
                        name: council.council_name,
                        icon: {
                            url: councilImage,
                            scaledSize: new google.maps.Size(16, 16),
                        },
                    };
                });
                setAllCouncilLinks(newtemp);
            });
            await Promise.all(
                allAdvertisementInfo.map(async (ad: any) => {
                    return {
                        icon: {
                            url: await getImageUrlFromS3('advertisement-images', ad.map_logo),
                            scaledSize: new google.maps.Size(16, 16),
                        },
                        lat: ad.lat,
                        lng: ad.lng,
                        link: ad.advertisement_link,
                    };
                }),
            ).then((res) => {
                setAllAdvertisementInfo(res);
            });
        })();
    }, []);
    useEffect(() => {
        const tempAds = allAdvertisementInfo.map((ad: any) => {
            return {
                ...ad,
                icon: {
                    ...ad.icon,
                    scaledSize: new google.maps.Size(
                        4 * zoomLevel < 40 ? 4 * zoomLevel : 40,
                        4 * zoomLevel < 40 ? 4 * zoomLevel : 40,
                    ),
                },
            };
        });
        setAllAdvertisementInfo(tempAds);
        const tempCouncils = allCouncilLinks.map((council: any) => {
            return {
                ...council,
                icon: {
                    ...council.icon,
                    scaledSize: new google.maps.Size(
                        4 * zoomLevel < 40 ? 4 * zoomLevel : 40,
                        4 * zoomLevel < 40 ? 4 * zoomLevel : 40,
                    ),
                },
            };
        });
        setAllCouncilLinks(tempCouncils);
    }, [zoomLevel]);

    const handleSelect = async (address: string) => {
        clearSuggestions();
        try {
            setValue(address, false);
            const { lat, lng } = getLatLng((await getGeocode({ address }))[0]);

            let selectedFeature: google.maps.Data.Feature | null = null;
            const latLng = CoodToGoogleLatLng({ lat, lng });
            const clickAddress = await getAddress(latLng);

            await mapRef.current.data.forEach((feature: google.maps.Data.Feature) => {
                const type = feature.getProperty('type') as string;
                if (
                    selectedFeature ||
                    (clickAddress.state && feature.getProperty('state') !== clickAddress.state) ||
                    (loadedMLBFeatures.includes(clickAddress.state) && type.startsWith('ou'))
                )
                    return;
                const geometry = feature.getGeometry();
                if (!geometry) return;
                if (geometry.getType() === 'Polygon') {
                    const polygon = geometry as google.maps.Data.Polygon;
                    const paths = polygon.getArray();
                    paths.forEach((path) => {
                        if (!selectedFeature && isInsidePolygon({ lat, lng }, path.getArray())) {
                            selectedFeature = feature;
                        }
                    });
                }
            });

            const event = {
                latLng,
                feature: selectedFeature,
                clickAddress,
                stop: () => {},
            };

            google.maps.event.trigger(mapRef.current.data, 'click', event);
            mapRef.current.setZoom(17);
            mapRef.current.setCenter({ lat, lng });
        } catch (error) {
            console.error('😱 Error: ', error);
        }
    };

    const handleInput = (e: { target: { value: string } }) => {
        setValue(e.target.value);
    };

    const getAddress = async (location: google.maps.LatLng | string) => {
        const result =
            location instanceof google.maps.LatLng
                ? await getGeocode({ location })
                : await getGeocode({ address: location });
        const address: Address = defaultAddress();
        const { address_components } = result[0];
        const { lat, lng } = getLatLng(result[0]);
        address.address = result[0].formatted_address;
        address.coordinate = { lat, lng };
        for (let i = 0; i < address_components.length; i++) {
            const component = address_components[i];
            for (let j = 0; j < component.types.length; j++) {
                const type = component.types[j];
                if (type === 'administrative_area_level_1') {
                    address.state = component.short_name;
                } else if (type === 'administrative_area_level_2') {
                    address.council = component.long_name;
                } else if (type === 'postal_code') {
                    address.postcode = component.short_name;
                } else if (type === 'locality') {
                    address.suburb = component.short_name;
                }
            }
        }
        if (address.state === 'ACT') address.council = 'Australian Capital Territory';
        return address;
    };

    // reload geojson when state change
    const switchState = async (newState: string) => {
        setLoading(true);
        const map = mapRef.current;
        dispatch(addressAndMapActions.setAddress(undefined));
        setState(newState);

        let newLoadedMLBFeatures = [...loadedMLBFeatures];

        if (newState !== '') {
            const stateCoordinate = stateCoordinates[newState as keyof typeof stateCoordinates];
            let overlayRequired = true;

            map.data.forEach((feature: any) => {
                const featureState = feature.getProperty('state');
                if (featureState === newState) {
                    if (feature.getProperty('type') === 'outline') {
                        if (!incompleteStates.includes(feature.getProperty('state')))
                            map.data.remove(feature);
                    } else {
                        overlayRequired = false;
                    }
                }
            });

            newLoadedMLBFeatures.push(newState);
            if (overlayRequired)
                await loadStateOverlay(newState, 'mlb_features', newLoadedMLBFeatures, true);
            setLoadedMLBFeatures((prev) => [...prev, newState]);
            map.setCenter(CoodToGoogleLatLng(stateCoordinate.center));
            map.setZoom(stateCoordinate.zoom);
        } else {
            mapRef.current.setZoom(4);
            mapRef.current.setCenter({ lat: -27.2744, lng: 133.7751 });
        }
        setLoading(false);
    };

    const loadStateOverlay = async (
        selectedState: string,
        folder: string,
        loadedMLBFeatures: string[],
        showSelectedStateOnly?: boolean,
    ) => {
        const mapData = mapRef.current.data;
        const geoJsonData = await loadGeoJsonForState(folder, selectedState);
        mapData.addGeoJson(geoJsonData);
        setMapStyle({
            selectedService: serviceGEOJSON,
            selectedState: showSelectedStateOnly ? selectedState : state,
            loadedMLBFeatures,
        });

        return geoJsonData;
    };

    const loadLgaBoundaries = async () => {
        const lgas = await Promise.all(
            Object.keys(stateCoordinates).map(async (state) => {
                try {
                    const url = await getFileFromS3('suwares', `geojson/LGA_${state}.json`);
                    const res = await fetch(url.results);
                    const geojson = await res.json();

                    return geojson;
                } catch {
                    return null;
                }
            }),
        );

        const mapData = mapRef.current.data;
        lgas.filter((lga) => lga !== null).forEach(async (lgaGeojson) => {
            mapData.addGeoJson(lgaGeojson);
        });
    };

    const onMapClick = async (
        e: google.maps.MapMouseEvent & { feature?: any; clickAddress?: Address },
    ) => {
        setLoading(true);
        const location = e.latLng!;
        const clickAddress = e.clickAddress ?? (await getAddress(location));

        if (e.feature) {
            if (e.feature.getProperty('type') === 'overlay') {
                clickAddress.council = clickAddress.council || e.feature.getProperty('councilName');
                clickAddress.rainfallStation = e.feature.getProperty('stationName');
                clickAddress.servicesAvailable = e.feature.getProperty('services_available');
                console.log(clickAddress);
            } else {
                const map = mapRef.current;
                let stateClicked = e.feature.getProperty('state');

                console.log(stateClicked);

                if (!loadedMLBFeatures.includes(stateClicked)) {
                    map.data.forEach((feature: any) => {
                        if (feature.getProperty('type') === 'outline') {
                            const state = feature.getProperty('state');
                            if (state === e.feature.getProperty('state'))
                                if (!incompleteStates.includes(state)) map.data.remove(feature);
                        }
                    });
                    const newLoadedMLBFeatures = [...loadedMLBFeatures, stateClicked];
                    const geoJsonRainfallData = await loadStateOverlay(
                        stateClicked,
                        'mlb_features',
                        newLoadedMLBFeatures,
                        false,
                    );
                    setLoadedMLBFeatures(newLoadedMLBFeatures);

                    const { stationName, councilName, services_available } =
                        await getFeatureFromGeojson(clickAddress, geoJsonRainfallData);

                    clickAddress.council = councilName;
                    clickAddress.rainfallStation = stationName;
                    clickAddress.servicesAvailable = services_available;
                }
            }
        }

        const coordinate = { lat: location.lat(), lng: location.lng() };
        setlastClickAddress(clickAddress);
        mapRef.current.setCenter(coordinate);
        setLoading(false);
    };

    useEffect(() => {
        (async () => {
            if (lastClickAddress !== undefined) {
                const newCouncilRules = await getCouncilRulesForOneMlb(
                    lastClickAddress.rainfallStation,
                ).then((res: any) => {
                    const groupedData = res.reduce((groups: any, item: any) => {
                        const device =
                            item.treatment_device === '-1'
                                ? 'General Rules'
                                : item.treatment_device;
                        if (!groups[device]) {
                            groups[device] = [];
                        }
                        groups[device].push(item);
                        return groups;
                    }, {});
                    const sortedObj: any = {};

                    for (const device in groupedData) {
                        const sortedArray = groupedData[device].sort((a: any, b: any) =>
                            a.priority.localeCompare(b.priority),
                        );
                        sortedObj[device] = sortedArray;
                    }
                    return sortedObj;
                });
                setShowCouncilRulesNotification(Object.keys(newCouncilRules).length > 0);
                setCouncilRules(newCouncilRules);
            }
        })();
    }, [lastClickAddress]);

    const loadMap = async () => {
        // load geojson
        await Promise.all(
            Object.keys(stateCoordinates).map(async (state) => {
                return loadStateOverlay(state, 'outlines', loadedMLBFeatures, false);
            }),
        );

        await loadLgaBoundaries();
        setLoading(false);
        mapRef.current.setZoom(4);
        mapRef.current.setCenter({ lat: -27.2744, lng: 133.7751 });
    };

    const setMapStyle = (mapStyle: MapStyle, showLga = false) => {
        const { selectedService, selectedState, loadedMLBFeatures } = mapStyle;
        const mapData = mapRef.current.data;
        mapData.setStyle((feature: any) => {
            const color = feature.getProperty('color');
            const type = feature.getProperty('type');
            const servicesAvailable = feature.getProperty('services_available');
            const featureState = feature.getProperty('state');

            if (type === 'council') {
                console.log(showCouncilIcons);
                return { ...LGAStyle, strokeOpacity: showLga ? 0.3 : 0 };
            }

            let overlayColor =
                (selectedState !== '' && featureState !== selectedState) ||
                !servicesAvailable?.includes(selectedService) ||
                (type === 'outline' &&
                    loadedMLBFeatures.includes(featureState) &&
                    selectedService !== 'O')
                    ? 'hsla(0, 0%, 0%, 0)'
                    : color;

            let zIndex = type.startsWith('ov') ? 2 : 1;
            if (feature?.Gg?.state === 'ACT') zIndex *= 3;

            return {
                ...OverlayStyle(overlayColor, zIndex),
            };
        });

        // store current state for show council toggle
        setCurrentMapStyle({ selectedService, selectedState, loadedMLBFeatures });
    };

    function handleZoomChanged() {
        if (map !== undefined) {
            setZoomLevel(map.getZoom()); //this refers to Google Map instance
        }
    }

    return (
        <div>
            <Grid container sx={{ padding: '0 0 1vh 0' }}>
                <Grid
                    item
                    md={4}
                    xs={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        gap: '0.5vh',
                    }}
                >
                    <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                        <FormControl variant="standard" sx={{ width: '100%' }} size="small">
                            <Select
                                labelId="location-select-label"
                                id="location-select"
                                value={state}
                                onChange={(e: SelectChangeEvent<string>) => {
                                    switchState(e.target.value);
                                }}
                                displayEmpty
                            >
                                <MenuItem value={''}>Select State/Country</MenuItem>
                                {Object.keys(stateCoordinates).map((state) => (
                                    <MenuItem key={state} value={state}>
                                        {state}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                        <Search
                            placeholder="Enter an Address"
                            handleSelect={handleSelect}
                            ready={ready}
                            handleInput={handleInput}
                            value={value}
                            status={status}
                            data={data}
                        />{' '}
                    </Grid>
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <FormControl
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '1rem',
                            width: 'fit-content',
                        }}
                        margin="none"
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <FormLabel component="legend">Select Tool</FormLabel>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <ButtonGroup sx={{ display: 'flex', width: '100%' }}>
                                    <Button
                                        sx={{ flexGrow: 1 }}
                                        variant={serviceGEOJSON === 'J' ? 'contained' : 'outlined'}
                                        color="primary"
                                        onClick={() => handleRadioChange('J')}
                                    >
                                        JCO
                                    </Button>
                                    <Button
                                        sx={{ flexGrow: 1 }}
                                        variant={serviceGEOJSON === 'S' ? 'contained' : 'outlined'}
                                        color="primary"
                                        onClick={() => handleRadioChange('S')}
                                    >
                                        Su Calculator / AI Tools
                                    </Button>
                                </ButtonGroup>
                                <ButtonGroup sx={{ display: 'flex', width: '100%' }}>
                                    <Button
                                        sx={{ flexGrow: 1 }}
                                        variant={serviceGEOJSON === 'A' ? 'contained' : 'outlined'}
                                        color="primary"
                                        onClick={() => handleRadioChange('A')}
                                    >
                                        Auditor
                                    </Button>
                                    <Button
                                        sx={{ flexGrow: 1 }}
                                        variant={serviceGEOJSON === 'O' ? 'contained' : 'outlined'}
                                        color="primary"
                                        onClick={() => handleRadioChange('O')}
                                    >
                                        OSD / BTB / Converter
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </FormControl>
                </Grid>
                <Grid
                    item
                    md={2}
                    xs={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        gap: '0.5vh',
                    }}
                >
                    <Grid
                        item
                        md={12}
                        xs={12}
                        style={{
                            display: 'flex',
                            gap: '1vw',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <p>Show Ads: </p>
                        <input
                            type="checkbox"
                            checked={showManufacturerAds}
                            onChange={() => setShowManufacturerAds(!showManufacturerAds)}
                        ></input>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                        style={{
                            display: 'flex',
                            gap: '1vw',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <p>Show Councils: </p>
                        <input
                            type="checkbox"
                            checked={showCouncilIcons}
                            onChange={(val) => {
                                const { checked } = val.target;
                                setShowCouncilIcons(checked);
                                if (currentMapStyle) {
                                    setMapStyle(currentMapStyle, checked);
                                }
                            }}
                        ></input>
                    </Grid>
                </Grid>
            </Grid>

            {loading && (
                <div
                    style={{
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '5vh 5vw',
                        zIndex: 10,
                        width: '100%',
                        left: 0,
                    }}
                >
                    <LoadingSpinner />
                </div>
            )}

            <GoogleMap
                mapContainerStyle={{
                    minHeight: '566px',
                    filter: loading ? 'blur(10px) brightness(0.7)' : '',
                }}
                zoom={6}
                onZoomChanged={() => handleZoomChanged()}
                center={
                    mapRef.current
                        ? mapRef.current.center
                        : CoodToGoogleLatLng(stateCoordinates.VIC.center)
                }
                options={mapOptions}
                onLoad={(map) => {
                    onLoad(map, mapRef, async () => await loadMap());
                    setMap(map);
                }}
                // onClick={onMapClick}
            >
                <Marker position={lastClickAddress?.coordinate!}>
                    {lastClickAddress && (
                        <InfoWindow
                            onCloseClick={() => {
                                setlastClickAddress(undefined);
                            }}
                        >
                            <InfoLayout
                                lastClickAddress={lastClickAddress}
                                setLastClickAddress={setlastClickAddress}
                                setOpenCouncilRulePage={setOpenCouncilRulePage}
                                showCouncilRulesNotification={showCouncilRulesNotification}
                            />
                        </InfoWindow>
                    )}
                </Marker>
                {allAdvertisementInfo.length > 0 &&
                    showManufacturerAds &&
                    allAdvertisementInfo.map((ad: any, index: number) => {
                        return (
                            <Marker
                                key={index}
                                position={{ lat: +ad.lat, lng: +ad.lng }}
                                icon={ad.icon}
                                onClick={() => openInNewTab(`${ad.link}`)}
                                opacity={0.9}
                            ></Marker>
                        );
                    })}
                {allCouncilLinks.length > 0 &&
                    showCouncilIcons &&
                    allCouncilLinks.map((council: any) => {
                        return (
                            <Marker
                                key={council.name}
                                position={{ lat: +council.lat, lng: +council.lng }}
                                icon={council.icon}
                                onClick={() => {
                                    openInNewTab(`${council.link}`);
                                }}
                                opacity={0.9}
                            ></Marker>
                        );
                    })}
            </GoogleMap>
            {openCouncilRulePage && (
                <CouncilRules
                    key={`council rules for${
                        lastClickAddress?.council
                            ? lastClickAddress.council
                            : address?.council ?? ''
                    }`}
                    name={`${
                        lastClickAddress?.council
                            ? lastClickAddress.council
                            : address?.council ?? ''
                    }`}
                    title={`Council Rules`}
                    councilRules={councilRules}
                    onClose={() => setOpenCouncilRulePage(false)}
                />
            )}
        </div>
    );
}

export default React.memo(Map);
