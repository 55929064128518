export interface MapDisplayOptions {
    lgaBoundaries: boolean;
    overlays: boolean;
}

export interface Coordinate {
    lat: number;
    lng: number;
}

export const LGAStyle = {
    fillColor: 'gray',
    fillOpacity: 0,
    strokeOpacity: 0.3,
    strokeWeight: 0.5,
    strokeColor: 'black',
    zIndex: 0,
};

export const OverlayStyle = (color: string, zIndex: number = 1) => {
    return {
        fillColor: color,
        fillOpacity: 0.5,
        strokeOpacity: 0.5,
        strokeWeight: 0,
        strokeColor: color,
        zIndex: zIndex,
    };
};

export interface Address {
    state: string;
    council: string;
    rainfallStation: string;
    suburb: string;
    postcode: string;
    address: string;
    coordinate: Coordinate;
    servicesAvailable: string[];
}

export const defaultAddress = (): Address => {
    return {
        state: '',
        council: '',
        address: '',
        rainfallStation: '',
        suburb: '',
        postcode: '',
        coordinate: { lat: 0, lng: 0 },
        servicesAvailable: [],
    };
};

export type MapLibraries = Array<
    'places' | 'geometry' | 'drawing' | 'localContext' | 'visualization'
>;

export interface MapStyle {
    selectedService: string;
    selectedState: string;
    loadedMLBFeatures: string[];
}
